<script>
import { required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { validateMimeType } from '@/support/customValidators/mimeTypeValidator'

export default {
  name: 'UsersBatchManage',
  directives: { mask },
  components: {
    FileField: () => import('@/components/general/FileField'),
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    Radio: () => import('@/components/general/Radio'),
    FormSection: () => import('@/components/general/FormSection')
  },

  data () {
    return {
      userStatusOptions: [
        {
          label: 'Completo e validado',
          value: 'completed_and_validated'
        },
        {
          label: 'Completo e não validado',
          value: 'completed_and_not_validated'
        },
        {
          label: 'Incompleto',
          value: 'incomplete'
        }
      ],

      formData: {
        file: null,
        status: 'incomplete'
      },

      allowedMimeTypes: [
        'text/csv',
        'text/plain',
        'application/vnd.ms-excel',
        'application/csv',
        'application/x-csv',
        'text/comma-separated-values',
        'text/x-comma-separated-values',
        'text/tab-separated-values',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ]
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),

    isRaeEnabled () {
      return this.activeModules.includes('rae')
    },

    isZipCodeEnabled () {
      return this.activeModules.includes('zip-code')
    },

    exampleFile () {
      return process.env.VUE_APP_API_URL + ((this.isRaeEnabled || this.isZipCodeEnabled) ? '/examples/batch/user.csv' : '/examples/batch/user-simple.csv')
    }
  },

  validations: {
    formData: {
      file: {
        required,
        fileValidation: function (file) {
          return validateMimeType(file, this.allowedMimeTypes)
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'attemptCreateUserBatch',
      'setFetching',
      'setFeedback'
    ]),

    submit () {
      this.createUserBatch()
    },

    createUserBatch () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.setFetching(true)

        this.attemptCreateUserBatch(this.formData).then(() => {
          this.setFeedback({ message: this.$t('community.users:batch.register.success') })
          this.leave()
        }).catch((e) => {
          if (e.data.error.message === 'batch_error_file_already_processing') {
            this.setFeedback({ message: this.$t('global.batch.error') })
          } else if (e.data.error.message === 'invalid_file_type') {
            this.setFeedback({ message: this.$t('global.validation.file') })
          } else {
            this.setFeedback({ message: this.$t('global.error') })
          }
        }).finally(() => {
          this.setFetching(false)
        })
      } else {
        this.showValidationErrors()
      }
    },

    showValidationErrors () {
      this.$nextTick(() => {
        this.setFeedback({ message: this.$t('users.manage:validation.error') })

        this.debounceEvent(() => {
          const hasErrorElements = document.querySelector('.has-error')
          const top = hasErrorElements.offsetTop - 50

          document.querySelector('.modal-blocker').scroll({
            top: top,
            behavior: 'smooth'
          })
        }, 500)
      })
    },

    leave () {
      if (this.$route.params.origin === 'batch.list') {
        this.$router.push({ name: 'batch.list' })
      } else {
        this.$router.push({ name: 'users.index' })
      }
    }
  }
}
</script>

<template>
  <Modal
    :active="true"
    :cancel-label="$route.params.origin === 'batch.list' ? $t('global:back') : ''"
    @close="leave"
  >
    <div class="modal-form modal-add-user">
      <span class="modal-subtitle">{{ $t('users.management:add.new.students') }}</span>
      <h2 class="modal-title">
        {{ $t('community.index:modal.title.batch.add') }}
      </h2>
      <div class="wrapper">
        <Action
          :text="$t('global:download.example')"
          type="link"
          :url="exampleFile"
          class="btn-export"
          primary
          dark
          download
        />
      </div>
      <form @submit.prevent="submit()">
        <FormSection :title="$t('global:upload.add.file.title')">
          <FileField
            v-model="formData.file"
            :label="$t('global:upload.add.file')"
            :validation="$v.formData.file"
            :accept="allowedMimeTypes.join()"
            dark
          />
        </FormSection>
        <FormSection
          title="Status dos usuários"
        >
          <Radio
            v-model="formData.status"
            dark
            :items="userStatusOptions"
          />
        </FormSection>
        <p class="upload-statement-obs">
          ** Para preservar a exibição correta dos caracteres, o arquivo enviado deve estar no formato de codificação UTF-8.
        </p>
        <p class="upload-statement-obs">
          ** Os usuários não receberão e-mails de confirmação de cadastro.
        </p>
        <div class="form-submit text-center">
          <Action
            :text="$t('global:register')"
            type="button"
            secondary
            large
            submit
            fixed-width
          />
        </div>
      </form>
    </div>
  </Modal>
</template>

<style>
  .profiles-selection {
    margin-top: 60px;
  }
  .wrapper {
    display:flex;
    justify-content: center;
    width:100%;
    margin-top: 20px;
  }
  .upload-statement-obs {
    margin-top: 10px;
    margin-left: 20px;
    font-size: 12px;
    font-style: italic;
    color: #FFF;
    opacity: 0.55;
  }
</style>
